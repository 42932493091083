import React from "react"

import MainBannerLogo from "./assets/MainBannerLogo.png"
import MediaMainBanner from "./assets/MediaMainBanner.webp"
import MediaMainBannerMobile from "./assets/MediaMainBannerMobile.webp"
import DesktopMediaMainBanner from "./assets/DesktopMediaMainBanner.png"

import styles from "./styles.module.scss"

export const mainBanner = {
	banner: {
		mediaContent: (
			<>
				<img
					className={styles.mainBanner__desktop}
					src={MediaMainBanner}
					alt="Евгения Чева, кофейня Щегол"
					loading="lazy"
				/>
				<img
					className={styles.mainBanner__tablet}
					src={MediaMainBannerMobile}
					alt="Евгения Чева, кофейня Щегол"
					loading="lazy"
				/>
			</>
		),
		desktopBanner: (
			<img src={DesktopMediaMainBanner} alt="Главный баннер" loading="lazy" />
		),
		logo: <img src={MainBannerLogo} alt="Логотип" loading="lazy" />,
		quote: (
			<>
				Путь от маленькой кофейни на районе до номинанта ресторанной премии.
				Кейс кофейни <span className={styles.quote}>«Щегол»</span>
			</>
		),
	},
	heading: "Как ежегодно увеличивать прибыль на 20%",
	textarea: (
		<p className={styles.leadParagraph}>
			В 2014 году Евгения Чева сменила сферу деятельности. Она ушла из офиса,
			стала бариста и поняла две вещи. Можно варить кофе в своём заведении и
			нужно учиться работать с зерном. Спустя четыре года появился «Щегол» — её
			собственная кофейня и первый проект в роли предпринимателя. Евгения
			рассказала, почему запускала свой проект без услуг консультантов, в чём
			секрет успеха её бизнеса и что помогает ему развиваться.
		</p>
	),
	video: {
		src: "https://www.youtube.com/embed/TkFhdqQSc-g",
		textarea: (
			<p className={styles.textarea}>
				«Если ты боишься — это нормально.
				<br />
				Страх — движущая сила»
			</p>
		),
	},
}
