import React from "react"
import classNames from "classnames"

import Fancybox from "../../../../../components/_V3/Fancybox"

import PromptIconOne from "./assets/PromptIconOne.png"
import PromptIconTwo from "./assets/PromptIconTwo.png"
import PromptIconThree from "./assets/PromptIconThree.png"
import PromptIconFour from "./assets/PromptIconFour.png"
import PromptIconFive from "./assets/PromptIconFive.png"
import PromptIconSix from "./assets/PromptIconSix.png"
import PromptIconEight from "./assets/PromptIconEight.png"
import PromptIconNine from "./assets/PromptIconNine.png"
import PromptIconTen from "./assets/PromptIconTen.png"
import PromptIconEleven from "./assets/PromptIconEleven.png"
import PromptIconTwelve from "./assets/PromptIconTwelve.png"
import PromptIconThirteen from "./assets/PromptIconThirteen.png"

import MediaContentOne from "./assets/MediaContentOne.webp"
import MediaContentTwo from "./assets/MediaContentTwo.webp"
import MediaContentThree from "./assets/MediaContentThree.webp"
import MediaContentFour from "./assets/MediaContentFour.webp"
import MediaContentFive from "./assets/MediaContentFive.webp"
import MediaContentSix from "./assets/MediaContentSix.webp"
import MediaContentSeven from "./assets/MediaContentSeven.webp"
import MediaContentEight from "./assets/MediaContentEight.webp"
import MediaContentNine from "./assets/MediaContentNine.webp"
import MediaContentTen from "./assets/MediaContentTen.webp"
import MediaContentEleven from "./assets/MediaContentEleven.webp"
import MediaContentTwelve from "./assets/MediaContentTwelve.webp"

import PromptImageOne from "./assets/PromptImageOne_new.png"
import PromptImageOneFullSize from "./assets/PromptImageOneFullSize.png"
// import ArrowLeftTop from "../assets/arrow-left-top.png";

import styles from "./styles.module.scss"

export const headingTextareaAndPrompt = [
	{
		heading: "Как всё начиналось",
		textarea: (
			<span>
				&laquo;Щегол&raquo;&nbsp;&mdash; это две <nobr>спешелти-кофейни</nobr>{" "}
				в&nbsp;центре Петербурга. Первую кофейню я&nbsp;открыла в&nbsp;мае 2018
				года на&nbsp;улице Радищева, вторую мы&nbsp;запустили в&nbsp;июле 2022
				года в&nbsp;книжном магазине &laquo;Буквоед&raquo; на&nbsp;Невском
				проспекте.
				<br />
				<br />
				Приятно, что нас знают не&nbsp;только в&nbsp;Петербурге&nbsp;&mdash;
				к&nbsp;нам приезжают ценители кофе из&nbsp;других городов. А&nbsp;ещё
				нас номинировали в&nbsp;разных ресторанных премиях города, например,
				&laquo;Time Out Петербург&raquo; как лучший кофейный и&nbsp;лучший
				миниатюрный проект в&nbsp;2020 году.
				<br />
				<br />
				Мы&nbsp;сами обжариваем зерно, придумываем и&nbsp;выпекаем десерты,
				проводим каппинги для гостей (дегустация кофе) и&nbsp;участвуем
				в&nbsp;городских мероприятиях. Никогда не&nbsp;думала, что у&nbsp;меня
				появится бизнес, который я&nbsp;буду считать своей работой
				и&nbsp;который будет приносить прибыль.
			</span>
		),
		prompt: (
			<div className={classNames(styles.promptTypeOne, styles.promptIconOne)}>
				<img src={PromptIconOne} alt="Иконка 1" loading="lazy" />
				<p>
					Мы сами обжариваем зерно, придумываем десерты, проводим каппинги для
					гостей
				</p>
			</div>
		),
	},
	{
		heading: "Какой опыт помог мне открыть успешную кофейню",
		textarea: (
			<span>
				Первая серьёзная подготовка для меня&nbsp;&mdash; оплачиваемая
				стажировка в&nbsp;московской сети Coffee Bean. Здесь преподаватели
				высокого класса принимали у&nbsp;нас аттестацию по&nbsp;теории
				и&nbsp;практике. Потом они взяли меня к&nbsp;себе в&nbsp;коллектив. Хотя
				у&nbsp;меня всё получалось, я&nbsp;понимала: в&nbsp;работе
				на&nbsp;большую сеть я&nbsp;многое упускаю.
				<br />
				<br />
				В&nbsp;это время друзья открыли небольшую <nobr>
					спешелти-кофейню
				</nobr>{" "}
				&laquo;Кофемаус&raquo; на&nbsp;<nobr>Садовой-Каретной</nobr>{" "}
				и&nbsp;позвали к&nbsp;себе. Вместе мы&nbsp;развивали этот
				проект&nbsp;&mdash; там я&nbsp;работала до&nbsp;открытия собственной
				кофейни.
				<br />
				<br />
				В&nbsp;это&nbsp;же время училась в&nbsp;The Specialty Coffee Association
				и&nbsp;продолжала узнавать новое: читала литературу о&nbsp;кофе
				и&nbsp;кофейной сфере, смотрела интервью с&nbsp;разными людьми,
				двигающими индустрию, посещала выставки и&nbsp;чемпионаты (например, PIR
				Expo, Coffee & Tea Expo). Все эти знания и&nbsp;опыт помогли в&nbsp;том,
				чтобы открыть свою первую кофейню.
			</span>
		),
		prompt: (
			<div className={classNames(styles.promptTypeOne, styles.promptIconTwo)}>
				<img src={PromptIconTwo} alt="Иконка 1" loading="lazy" />
				<p>В работе на большую сеть я многое упускала</p>
			</div>
		),
		mediaContent: (
			<div className={styles.mediaContent__container}>
				<div className={styles.mediaContent}>
					<Fancybox>
						<a
							data-fancybox="image1"
							href={MediaContentOne}
							className={styles.media}
						>
							<img src={MediaContentOne} alt="Каппинг в Щегле" loading="lazy" />
						</a>
					</Fancybox>

					<p
						className={classNames(
							styles.mediaContentDesc,
							styles.mediaContentOneDesc
						)}
					>
						Каппинг в «Щегле»
					</p>
				</div>
				<div className={styles.mediaContent}>
					<Fancybox>
						<a
							data-fancybox="image2"
							href={MediaContentTwo}
							className={styles.media}
						>
							<img
								src={MediaContentTwo}
								alt="Дегустируем привезенные из Европы лоты"
								loading="lazy"
							/>
						</a>
					</Fancybox>
					<p
						className={classNames(
							styles.mediaContentDesc,
							styles.mediaContentTwoDesc
						)}
					>
						Дегустируем привезённые
						<br />
						из Европы лоты
					</p>
				</div>
			</div>
		),
	},
	{
		heading: "Лучше открыть, чем не открыть",
		textarea: (
			<span className={styles.widerTextarea}>
				Я&nbsp;сомневалась, в&nbsp;каком городе открывать кофейню&nbsp;&mdash;
				в&nbsp;Москве или Петербурге, сделать её на&nbsp;большое количество
				гостей или миниатюрной. На&nbsp;мои страхи мой инвестор ответил:
				&laquo;Если проект не&nbsp;станет успешен, то&nbsp;самой большой потерей
				для тебя будет время&raquo;. И&nbsp;я&nbsp;решилась.
				<br />
				<br />
				Теперь, спустя 4 года, говорю всем, кто хочет запустить свой проект:
				страхи есть всегда. Было&nbsp;бы странно, если&nbsp;бы они
				не&nbsp;возникали. Делай то, что должен и&nbsp;будь что будет. Если
				ты&nbsp;боишься&nbsp;&mdash; это нормально. Страх&nbsp;&mdash; движущая
				сила.
			</span>
		),
		prompt: (
			<div className={classNames(styles.promptTypeOne, styles.promptIconThree)}>
				<picture>
					<source media="(max-width: 767px)" srcSet={PromptIconTwo} />
					<img src={PromptIconThree} alt="Иконка 2" loading="lazy" />
				</picture>
				<p>
					Если проект не станет успешен, то самой большой потерей будет время
				</p>
			</div>
		),
		mediaContent: (
			<div className={styles.mediaContent}>
				<Fancybox>
					<a
						data-fancybox="image3"
						href={MediaContentThree}
						className={styles.media}
					>
						<img
							src={MediaContentThree}
							alt="«Щегол» на улице Радищева"
							loading="lazy"
						/>
					</a>
				</Fancybox>

				<p
					className={classNames(
						styles.mediaContentDesc,
						styles.mediaContentThreeDesc
					)}
				>
					«Щегол» на улице Радищева
				</p>
			</div>
		),
	},
	{
		heading: "Как появился инвестор",
		textarea: (
			<span className={styles.widerTextarea}>
				У&nbsp;меня был приятель, который хотел открыть{" "}
				<nobr>офлайн-бизнес</nobr>. А&nbsp;я&nbsp;хотела свою кофейню. Карты
				сошлись. &laquo;Давай делать бизнес&raquo;. Когда он&nbsp;мне это
				написал, я&nbsp;путешествовала по&nbsp;Индии. Я&nbsp;тогда недоумевала:
				&laquo;Какой бизнес, я&nbsp;же даже не&nbsp;в&nbsp;России?!&raquo;.
				И&nbsp;вот я&nbsp;приехала, мы&nbsp;сели за&nbsp;стол переговоров,
				и&nbsp;всё завертелось.
				<br />
				<br />
				Мечты абсолютно достаточно для начала. Но&nbsp;дальше одного вдохновения
				не&nbsp;хватит, чтобы, например, провести инвентаризацию или сделать
				закупки. Это можно представить так: ты&nbsp;запускаешь
				ракету&nbsp;&mdash; всё классно, она летит, но&nbsp;затем тебе нужно её
				поддерживать. Но&nbsp;делать проекты из&nbsp;расчёта, что это 100% будет
				прибыльный бизнес, тоже нельзя. Должна быть идея: а&nbsp;это уже нечто
				большее, чем желание заработать деньги.
			</span>
		),
		prompt: (
			<div className={classNames(styles.promptTypeOne, styles.promptIconFour)}>
				<picture>
					<source media="(max-width: 767px)" srcSet={PromptIconThree} />
					<img src={PromptIconFour} alt="Иконка 4" loading="lazy" />
				</picture>
				<p>
					Приятель хотел открыть офлайн-бизнес, а я — кофейню. Карты сошлись!
				</p>
			</div>
		),
		mediaContent: (
			<div
				className={classNames(
					styles.mediaContent__container,
					styles.mediaContent__container_two
				)}
			>
				<div
					className={classNames(styles.mediaContent, styles.mediaContentFour)}
				>
					<Fancybox>
						<a
							data-fancybox="image4"
							href={MediaContentFour}
							className={styles.media}
						>
							<img
								src={MediaContentFour}
								alt="Евгения Чева, владелица кофейни Щегол"
								loading="lazy"
							/>
						</a>
					</Fancybox>

					<p
						className={classNames(
							styles.mediaContentDesc,
							styles.mediaContentFourDesc
						)}
					>
						Евгения и её пес Джейсон <br />
						во второй кофейне «Щегол»
					</p>
				</div>
				<div className={styles.mediaContent}>
					<Fancybox>
						<a
							data-fancybox="image5"
							href={MediaContentFive}
							className={styles.media}
						>
							<img
								src={MediaContentFive}
								alt="Пёс Джейсон во второй кофейне Щегол"
								loading="lazy"
							/>
						</a>
					</Fancybox>

					<p
						className={classNames(
							styles.mediaContentDesc,
							styles.mediaContentFiveDesc
						)}
					>
						Евгения и её пес Джейсон <br />
						во второй кофейне «Щегол»
					</p>
				</div>
			</div>
		),
	},
	{
		heading: "Когда ты делаешь свой проект, это интересно",
		textarea: (
			<span className={styles.widerTextarea}>
				Мой путь по&nbsp;запуску кофейни можно описать так: на&nbsp;каждом этапе
				ты&nbsp;сталкиваешься с&nbsp;трудностями&nbsp;&mdash; поиск помещения,
				заключение договоров с&nbsp;поставщиками и&nbsp;так далее. Для тебя это
				только первые шаги&nbsp;&mdash; ты&nbsp;не&nbsp;знаешь, как должно быть
				и&nbsp;как правильно. Да, можно спросить или нанять консалтинг,
				но&nbsp;когда ты&nbsp;сам делаешь свой проект, это куда интереснее.
				<br />
				<br />
				Были сложности со&nbsp;строительной бригадой, которая ничего
				не&nbsp;делала. Я&nbsp;не&nbsp;знала, что нужно заключить договор
				и&nbsp;прописать условия. Тогда&nbsp;бы это не&nbsp;было проблемой.
				А&nbsp;ещё в&nbsp;первый день работы мы&nbsp;повредили шланг
				в&nbsp;дорогой кофемашине. Но&nbsp;всё решаемо&nbsp;&mdash; нашли
				и&nbsp;шланг, и&nbsp;мастера. Или поиск людей&nbsp;&mdash; сначала
				ты&nbsp;не&nbsp;знаешь, кто будет работать в&nbsp;твоём проекте, как
				собрать команду. Но&nbsp;на&nbsp;деле это всё происходит постепенно.
				<br />
				<br />
				Выстраивать систему приходилось шаг за&nbsp;шагом. Например, сначала
				мы&nbsp;закупали зерно у&nbsp;поставщиков, сейчас обжариваем сами.
				Десерты тоже вначале заказывали готовые, теперь у&nbsp;нас своё
				кондитерское производство и&nbsp;мы&nbsp;поставляем десерты
				в&nbsp;другие кофейни.
			</span>
		),
		prompt: (
			<div className={classNames(styles.promptTypeOne, styles.promptIconFive)}>
				<img src={PromptIconFive} alt="Иконка 4" loading="lazy" />
				<p>
					Я&nbsp;не&nbsp;знала, как собрать команду, строители не&nbsp;хотели
					работать и&nbsp;в&nbsp;день открытия мы&nbsp;повредили шланг
					в&nbsp;дорогой кофемашине
				</p>
			</div>
		),
		mediaContent: (
			<div className={styles.mediaContent}>
				<Fancybox>
					<a
						data-fancybox="image6"
						href={MediaContentSix}
						className={styles.media}
					>
						<img src={MediaContentSix} alt="интерьер кофейни" loading="lazy" />
					</a>
				</Fancybox>
			</div>
		),
	},
	{
		heading:
			"Зелёное зерно: почему выгоднее покупать его и обжаривать самостоятельно",
		textarea: (
			<span>
				Когда мы&nbsp;поняли, что хотим лучше контролировать качество продукта,
				стали покупать зелёное зерно и&nbsp;обжаривать его под руководством
				опытного обжарщика. Для этого арендуем обжарочные
				устройства&nbsp;&mdash; ростеры. Сначала обжариваем пробную партию,
				тестируем, подходит&nbsp;ли нам такая обжарка или недостаточно той
				кислотности, сладости, глубины вкуса, которой нам&nbsp;бы хотелось.
				<br />
				<br />
				Такой подход позволяет ещё и&nbsp;сэкономить: уже готовое зерно этого
				класса обойдётся дороже, чем зелёное. В&nbsp;среднем, стоимость зелёного
				зерна начинается от&nbsp;10&nbsp;долларов. Есть цена ниже, есть
				выше&nbsp;&mdash; это уже опции по&nbsp;его качеству.
				<br />
				<br />
				Стоимость обжарки&nbsp;&mdash; примерно 30% от&nbsp;цены. Нужно
				учитывать, например, что зерно при обжарке теряет в&nbsp;среднем 17%
				от&nbsp;своего изначального веса&nbsp;&mdash; ужаривается. Также
				в&nbsp;стоимость зерна входят расходы на&nbsp;транспортировку
				и&nbsp;упаковку.
			</span>
		),
		prompt: (
			<div className={classNames(styles.promptTypeTwo, styles.promptImageOne)}>
				<Fancybox>
					<a
						data-fancybox="image7"
						href={PromptImageOneFullSize}
						className={styles.media}
					>
						<img
							src={PromptImageOne}
							alt="зёрна собственной обжарки"
							loading="lazy"
						/>
					</a>
				</Fancybox>
				{/*<img className={styles.arrowLeftTop} src={ArrowLeftTop} alt=""/>*/}
				<p className={styles.promptOneDesc}>
					Зёрна нашей обжарки можно попробовать в кофейне и купить домой
				</p>
			</div>
		),
	},
	{
		prompt: (
			<div className={classNames(styles.promptTypeOne, styles.promptIconSix)}>
				<img src={PromptIconSix} alt="Иконка 4" loading="lazy" />
				<p>
					Сначала мы&nbsp;закупали зерно у&nbsp;поставщиков, сейчас обжариваем
					сами&nbsp;&mdash; это позволяет ещё и&nbsp;сэкономить
				</p>
			</div>
		),
		mediaContent: (
			<div className={styles.mediaContent__container}>
				<div className={styles.mediaContent}>
					<Fancybox>
						<a
							data-fancybox="image8"
							href={MediaContentSeven}
							className={styles.media}
						>
							<img
								src={MediaContentSeven}
								alt="завтрак в кофейне Щегол"
								loading="lazy"
							/>
						</a>
					</Fancybox>

					<p
						className={classNames(
							styles.mediaContentDesc,
							styles.mediaContentSevenDesc
						)}
					>
						Мы сами делаем завтраки
					</p>
				</div>
				<div className={styles.mediaContent}>
					<Fancybox>
						<a
							data-fancybox="image9"
							href={MediaContentEight}
							className={styles.media}
						>
							<img
								src={MediaContentEight}
								alt="фирменная каша"
								loading="lazy"
							/>
						</a>
					</Fancybox>
					<p
						className={classNames(
							styles.mediaContentDesc,
							styles.mediaContentEightDesc
						)}
					>
						Наши каша и фирменные сырники
					</p>
				</div>
			</div>
		),
	},
	{
		heading: "Дать людям то, от чего им станет проще жить",
		textarea: (
			<span className={styles.widerTextarea}>
				Моя сила&nbsp;&mdash; люди, с&nbsp;которыми работаю. Я&nbsp;до&nbsp;сих
				пор вдохновляюсь своим проектом. Когда довольны гости, когда есть заказы
				на&nbsp;сайте, когда гости звонят и&nbsp;спрашивают, остался&nbsp;ли ещё
				кусочек вишнёвого пирога. В&nbsp;этом и&nbsp;есть сила.
				<br />
				<br />
				Люди возвращаются за&nbsp;эмоциями, настроением, минутой спокойствия.
				Быть предпринимателем&nbsp;&mdash; это как раз давать людям то,
				от&nbsp;чего им&nbsp;становится чуть проще жить.
				Предприниматель&nbsp;&mdash; тот, кто может использовать изменчивые
				ситуации в&nbsp;свою пользу. Умеет грамотно распределять ресурсы,
				позволяющие бизнесу работать, развиваться и&nbsp;двигаться дальше.
			</span>
		),
	},
	{
		heading: "Проект не может работать без моего участия",
		textarea: (
			<span className={styles.widerTextarea}>
				Бизнес в&nbsp;сфере общепита требует постоянного внимания владельца или
				управленца. На&nbsp;данный момент я&nbsp;считаю, что проект
				не&nbsp;может работать без моего участия, но&nbsp;я&nbsp;могу отпустить
				контроль на&nbsp;<nobr>какое-то</nobr> время. Например, уехать заграницу
				и&nbsp;управлять проектом оттуда вряд&nbsp;ли получится, но&nbsp;выпасть
				на&nbsp;месяц из&nbsp;рабочего графика можно.
				<br />
				<br />
				На&nbsp;моих ребят можно положиться: каждый знает свой пул обязанностей
				и&nbsp;выполняет их. Это я&nbsp;поняла, когда долго была
				на&nbsp;больничном и&nbsp;не&nbsp;появлялась в&nbsp;кофейне.
				Прихожу&nbsp;&mdash; каждый занимается своим делом, в&nbsp;кофейне
				чисто, гости сидят за&nbsp;столиками. Я&nbsp;не&nbsp;хожу с&nbsp;листом
				менеджера и&nbsp;не&nbsp;даю указания по&nbsp;каждой мелочи. У&nbsp;нас
				выстроена система, что и&nbsp;как должно работать&nbsp;&mdash; каждый
				член команды понимает свою миссию и&nbsp;что он&nbsp;здесь делает.
			</span>
		),
		prompt: (
			<div className={classNames(styles.promptTypeOne, styles.promptIconEight)}>
				<img src={PromptIconEight} alt="гости кофейни" loading="lazy" />
				<p>
					Быть предпринимателем&nbsp;&mdash; давать людям то, от&nbsp;чего
					им&nbsp;становится жить проще
				</p>
			</div>
		),
	},
	{
		prompt: (
			<div className={classNames(styles.promptTypeOne, styles.promptIconNine)}>
				<img
					src={PromptIconNine}
					alt="кассовый терминал Quick Resto"
					loading="lazy"
				/>
				<p>
					У&nbsp;нас каждый член команды понимает свою миссию и&nbsp;что&nbsp;он
					делает в&nbsp;проекте.
				</p>
			</div>
		),
		mediaContent: (
			<div className={styles.mediaContent}>
				<Fancybox>
					<a
						data-fancybox="image10"
						href={MediaContentNine}
						className={styles.media}
					>
						<img src={MediaContentNine} alt="гости кофейни" loading="lazy" />
					</a>
				</Fancybox>

				<p
					className={classNames(
						styles.mediaContentDesc,
						styles.mediaContentNineDesc
					)}
				>
					В&nbsp;&laquo;Щегол&raquo; люди возвращаются за&nbsp;эмоциями,
					настроением, минутой спокойствия
				</p>
			</div>
		),
	},
	{
		heading: "Что решает половину моих задач",
		textarea: (
			<span>
				В&nbsp;кофейнях у&nbsp;нас установлена система автоматизации Quick
				Resto&nbsp;&mdash; она облегчает мою работу и&nbsp;решает половину моих
				задач. С&nbsp;помощью системы у&nbsp;меня ведётся бухгалтерия,
				я&nbsp;составляю отчёты, слежу за&nbsp;важными для меня показателями
				доходов и&nbsp;расходов. В&nbsp;программу заложено много показателей:
				отчётность за&nbsp;месяц или полугодие, отчётность по&nbsp;продуктам или
				блюдам. Всё продумано до&nbsp;мельчайшего нюанса.
				<br />
				<br />
				Чаще всего я&nbsp;анализирую месячные отчёты. Работаю с&nbsp;системой
				все четыре года и&nbsp;могу отследить, какие показатели были
				за&nbsp;каждый период. Когда в&nbsp;процентном соотношении видишь, как
				ты&nbsp;растёшь, это мотивирует двигаться дальше. За&nbsp;четыре года
				динамика роста в&nbsp;среднем 15&ndash;20%. Я&nbsp;считаю, что это очень
				хорошие показатели.
			</span>
		),
	},
	{
		prompt: (
			<div className={classNames(styles.promptTypeOne, styles.promptIconTen)}>
				<img src={PromptIconTen} alt="Иконка 4" loading="lazy" />
				<p>Quick Resto облегчает мою работу и решает половину задач</p>
			</div>
		),
		mediaContent: (
			<div className={styles.mediaContent}>
				<Fancybox>
					<a
						data-fancybox="image11"
						href={MediaContentTen}
						className={styles.media}
					>
						<img
							src={MediaContentTen}
							alt="кассовый терминал Quick Resto"
							loading="lazy"
						/>
					</a>
				</Fancybox>

				<p
					className={classNames(
						styles.mediaContentDesc,
						styles.mediaContentTenDesc
					)}
				>
					Кассовый терминал Quick Resto
				</p>
			</div>
		),
	},
	{
		heading: "В чём секрет успеха?",
		textarea: (
			<span>
				Мои&nbsp;<nobr>друзья-предприниматели</nobr> из&nbsp;кофейной индустрии
				говорят, что мой секрет в&nbsp;хорошем маркетинге и&nbsp;качественном
				продукте. Я&nbsp;просто делаю так, как хочу, чтобы делали
				все&nbsp;&mdash; с&nbsp;полной отдачей и&nbsp;интересом. Секрет
				успеха&nbsp;&mdash; любовь к&nbsp;делу и&nbsp;выстраивание системности.
				Если ты&nbsp;любишь то, что делаешь, если тебе нравится это делать,
				то&nbsp;никакой тайной хитрости нет.
			</span>
		),
	},
	{
		heading: "Открытие второй кофейни",
		textarea: (
			<span>
				В&nbsp;городе стало на&nbsp;одну &laquo;птичку&raquo;
				больше&nbsp;&mdash; мы&nbsp;открыли второй &laquo;Щегол&raquo;
				в&nbsp;книжном &laquo;Буквоед&raquo; на&nbsp;Невском, 46. Теперь
				у&nbsp;нас есть свой цех, чтобы делать ещё больше вкусных десертов. Кофе
				так&nbsp;же обжариваем сами, так что вкус и&nbsp;качество
				не&nbsp;подведёт.
				<br />
				<br />
				В&nbsp;дальнейшем было&nbsp;бы здорово сделать свой проект в&nbsp;Европе
				или Азии. Раньше я&nbsp;считала, что открою кофейню в&nbsp;Берлине,
				но&nbsp;в&nbsp;современных реалиях про это пока не&nbsp;думаю.
			</span>
		),
		prompt: (
			<div
				className={classNames(styles.promptTypeOne, styles.promptIconEleven)}
			>
				<img src={PromptIconEleven} alt="Иконка 4" loading="lazy" />
				<p>
					Секрет успеха&nbsp;&mdash; любовь к&nbsp;делу и&nbsp;выстраивание
					системности
				</p>
			</div>
		),
		mediaContent: (
			<div className={styles.mediaContent}>
				<Fancybox>
					<a
						data-fancybox="image12"
						href={MediaContentEleven}
						className={styles.media}
					>
						<img
							src={MediaContentEleven}
							alt="Проект кофейни «Щегол» на Невском, 46"
							loading="lazy"
						/>
					</a>
				</Fancybox>

				<p
					className={classNames(
						styles.mediaContentDesc,
						styles.mediaContentElevenDesc
					)}
				>
					Проект кофейни «Щегол» на Невском, 46
				</p>
			</div>
		),
	},
	{
		heading: "С опытом бизнес открывать сложнее",
		textarea: (
			<span>
				На&nbsp;момент открытия первой кофейни мне было гораздо
				проще&nbsp;&mdash; ничего не&nbsp;знаешь, выясняешь всё в&nbsp;процессе
				и&nbsp;просто делаешь. Тебе нужно зарегистрировать бизнес, найти
				команду, помещение и&nbsp;контрагентов. Ты&nbsp;понимаешь, что эти
				ступеньки нужно преодолеть, чтобы проект работал.
				<br />
				<br />
				Удивительно, но&nbsp;я&nbsp;до&nbsp;сих пор узнаю <nobr>
					что-то
				</nobr>{" "}
				новое о&nbsp;российском бизнесе&nbsp;&mdash; постоянно{" "}
				<nobr>что-то</nobr> меняется в&nbsp;законах и&nbsp;налоговых порядках.
				Но&nbsp;радует, что взаимодействие с&nbsp;госструктурами сейчас
				упростилось. Можно в&nbsp;электронном виде подать документы онлайн или
				задать вопрос службе поддержки. И&nbsp;достаточно быстро получить ответ.
				<br />
				<br />
				И&nbsp;важно понимать&nbsp;&mdash; если у&nbsp;<nobr>кого-то</nobr>{" "}
				будет желание сказать, что твой бизнес функционирует с&nbsp;нарушениями,
				он&nbsp;придёт и&nbsp;это сделает. Поэтому ты&nbsp;просто делаешь
				обязательные документы, платишь налоги, соблюдаешь
				стандарты&nbsp;&mdash; и&nbsp;работаешь дальше.
			</span>
		),
		prompt: (
			<div
				className={classNames(styles.promptTypeOne, styles.promptIconTwelve)}
			>
				<img src={PromptIconTwelve} alt="Иконка 4" loading="lazy" />
				<p>Взаимодействие с госструктурами в России сейчас упростилось</p>
			</div>
		),
	},
	{
		heading: "Советы тем, кто хочет открыть свою кофейню",
		textarea: (
			<span className={styles.widerTextarea}>
				Считается, что маленькая кофейня на&nbsp;районе&nbsp;&mdash; это простой
				и&nbsp;прибыльный бизнес. Это не&nbsp;так. Я&nbsp;бы хотела предостеречь
				начинающих предпринимателей от&nbsp;мысли, что будет легко. Прежде всего
				нужно грамотно оценить свои силы и&nbsp;выстроить представление
				о&nbsp;том, как всё будет работать. Ответьте себе на&nbsp;вопросы:
				<ul className={styles.shchegolList}>
					<li>Хватит&nbsp;ли у&nbsp;меня сил на&nbsp;поддержание проекта?</li>
					<li>
						Достаточно&nbsp;ли у&nbsp;меня знаний о&nbsp;том, как будет
						развиваться бизнес в&nbsp;ближайшие три месяца, шесть месяцев, один
						год?
					</li>
				</ul>
			</span>
		),
		prompt: (
			<div
				className={classNames(styles.promptTypeOne, styles.promptIconThirteen)}
			>
				<img src={PromptIconThirteen} alt="Иконка 4" loading="lazy" />
				<p>
					Считается, что кофейня&nbsp;&mdash; это простой и&nbsp;прибыльный
					бизнес. Но&nbsp;это не&nbsp;так!
				</p>
			</div>
		),
		mediaContent: (
			<div
				className={classNames(styles.mediaContent, styles.mediaContent__last)}
			>
				<Fancybox>
					<a
						data-fancybox="image13"
						href={MediaContentTwelve}
						className={styles.media}
					>
						<img
							src={MediaContentTwelve}
							alt="Как открыть свою кофейню"
							loading="lazy"
						/>
					</a>
				</Fancybox>
			</div>
		),
	},
	{
		textarea: (
			<span>
				Планировать сейчас очень сложно, но&nbsp;в&nbsp;голове всё равно должна
				быть примерная смета. Ещё прокачивайте разные навыки: эрудицию, знание
				законов, этапы и&nbsp;особенности построения ресторанного бизнеса,
				выносливость, стрессоустойчивость. Следите за&nbsp;трендами
				в&nbsp;кофейной сфере и&nbsp;ресторанном бизнесе. Вы&nbsp;должны
				разбираться понемногу во&nbsp;всём.
			</span>
		),
	},
	{
		heading: "И в заключение — почему же «Щегол»?",
		textarea: (
			<span className={styles.widerTextarea}>
				&laquo;Щегол&raquo;&nbsp;&mdash; название романа английской писательницы
				Донны Тарт, которая писала произведение 10 лет и&nbsp;получила
				за&nbsp;него Пулитцеровскую премию. Когда я&nbsp;прочитала эту книгу,
				она меня сильно воодушевила. Это было ещё до&nbsp;запуска своего
				проекта. Тогда я&nbsp;подумала: &laquo;Если мне придётся{" "}
				<nobr>что-то</nobr> называть, пусть это будет
				&bdquo;Щегол&ldquo;&raquo;. Так и&nbsp;вышло.
			</span>
		),
	},
]
